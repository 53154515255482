<template>
  <div>
    <p v-if="selectedVideo">
      <video
        style="
          width: 100%;
          max-width: 800px;
          border: 5px solid #d5de20;
          border-radius: 0px;
        "
        :src="selectedVideo"
        alt=""
        ref="myvideo"
        muted
        autoplay
      />
    </p>
  </div>
</template>

<script>
import video1 from "../../assets/VideoAd/1.mp4";
import video2 from "../../assets/VideoAd/2.mp4";
import video3 from "../../assets/VideoAd/3.mp4";

export default {
  data: () => ({
    videos: [video1, video2, video3],
    selectedVideo: null,
  }),
  methods: {
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
  },
  created() {
    this.selectedVideo = this.randomItem(this.videos);
  },
};
</script>

<style lang="scss" scoped></style>
