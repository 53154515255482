var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"loader",class:{ hide: !_vm.loading }}),_c('div',{class:{ hide: _vm.loading }},[_c('div',{staticClass:"wrapper-for-spinner"},[_c('div',{staticClass:"spinner__spinandwin justify-center mt-15"},[_c('div',{staticClass:"spinner__spinandwin__block"},[_c('img',{staticClass:"spinner__spinandwin__img",attrs:{"src":_vm.images.logo,"alt":"logo"}})])]),_vm._m(0),_c('div',{staticClass:"spinner__block spinner-content d-flex flex-column"},[_c('div',{ref:"wheel",staticClass:"deal-wheel"},[_c('img',{staticClass:"wheel-spinner",attrs:{"src":_vm.images.wheel,"alt":"wheel spinner","rel":"preload"}}),_c('div',{staticClass:"arrow-spin-wrap"},[_c('img',{staticClass:"spinner__arrow",attrs:{"src":_vm.images.arrow,"alt":"arrow spinner"}})]),_c('button',{staticClass:"spinner__center",on:{"click":function($event){{
                {
                  _vm.balance >= 0 && _vm.isFreeSpin
                    ? _vm.wathingad()
                    : _vm.redirectToAddSpins();
                }
              }}}},[_c('span',[_vm._v(_vm._s(_vm.isSpining != true && _vm.balance >= 0 && _vm.isFreeSpin ? "Free Spin" : "$" + _vm.priceSpin)+" ")])]),_c('ul',{ref:"spinner",staticClass:"spinner",style:({ background: _vm.bgSectorsSpinner })},_vm._l((_vm.offersForSpinner),function({ alias },index){return _c('li',{key:index,staticClass:"prize",class:_vm.activeClass && _vm.winner.name === _vm.name ? _vm.selectedClass : '',style:({
                transform: 'rotate(' + _vm.rotationDefault(index) + 'deg)',
              })},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(alias))])])}),0)]),_c('span',{staticClass:"spinner__text"},[_vm._v("Spins: "+_vm._s(_vm.spinner_quantity === null ? 0 : _vm.spinner_quantity))])]),_c('div',{staticClass:"spinner__controls"},[_c('button',{ref:"attention-btn",staticClass:"spinner__button",class:_vm.isSpining ? 'wait' : _vm.balance >= 0 && _vm.isFreeSpin ? 'animbtn' : '',on:{"click":function($event){{
              {
                _vm.isSpining != true && _vm.balance >= 0 && _vm.isFreeSpin
                  ? _vm.wathingad()
                  : _vm.runSpinner();
              }
            }}}},[_vm._v(" "+_vm._s(_vm.isSpining ? "Wait" : _vm.balance >= 0 && _vm.isFreeSpin ? "Free Spin" : "Spin")+" ")]),_c('button',{staticClass:"spinner__button",on:{"click":function($event){return _vm.redirectToAddSpins()}}},[_vm._v(" Add Spins ")])]),_c('button',{staticClass:"spinner__goback",on:{"click":function($event){return _vm.goback()}}},[_vm._v("Back")]),_c('v-dialog',{staticClass:"video",model:{value:(_vm.modalVideoAd),callback:function ($$v) {_vm.modalVideoAd=$$v},expression:"modalVideoAd"}},[_c('v-card',{staticStyle:{"padding":"0","background-color":"#000"},attrs:{"width":"100vw; "}},[_c('div',{staticClass:"video__btns"},[_c('div',{staticClass:"video__button"},[_vm._v(_vm._s(_vm.seconds))]),_c('div',{staticClass:"video__title"},[_vm._v(" Please, Watch Short Ad To Get Your Free Spin. ")]),(_vm.skipadbtn)?_c('div',{staticClass:"video__button",on:{"click":function($event){return _vm.skipad()}}},[_vm._v(" Skip Ad ")]):_vm._e(),(_vm.skiptime)?_c('div',{staticClass:"video__button"},[_vm._v(" Skip video in "+_vm._s(_vm.sec)+" ")]):_vm._e()]),_c('VideoAd',{staticStyle:{"padding":"15px"}})],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner__spinandwin"},[_c('div',{staticClass:"spinner__spinandwin__block"},[_vm._v(" SPIN "),_c('br'),_vm._v(" AND "),_c('br'),_vm._v("WIN ")]),_c('div',{staticClass:"spinner__spinandwin__block"},[_vm._v(" APRIL "),_c('br'),_vm._v(" PROMO "),_c('br')])])
}]

export { render, staticRenderFns }