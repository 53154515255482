<template>
  <div>
    <div class="full" v-if="loading"><div class="loader"></div></div>
    <div v-else>
      <div class="svg">
        <div class="svg__addtime__topleft">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            viewBox="-0.6 -0.2 211.9 147.0"
            zoomAndPan="magnify"
            style="fill: rgb(0, 0, 0)"
            role="img"
            aria-label="Abstract watercolor brushstroke element"
          >
            <g id="__id982_shic7e1igo">
              <path
                d="M60.629.279c-.5-.077-1-.19-1.508-.225C55.184-.218,51.344.5,48.435,3.126a35.784,35.784,0,0,0-6.087,7.731,84.512,84.512,0,0,0-4.222,8.666C36,24.018,34.349,28.781,31.1,32.659a54.63,54.63,0,0,1-6.491,7.079,94.446,94.446,0,0,1-9.015,6.08c-2.957,1.94-5.582,4.079-6.219,7.905-.123.738-1.079,1.368-1.713,1.984-.7.682-1.56,1.214-2.2,1.946-2.122,2.441-2.925,5.5-3.754,8.519C.568,70.341-.619,74.546.367,78.915a65.935,65.935,0,0,0,2.4,8.542,20.21,20.21,0,0,0,9.513,11.16c7.693,4.059,16.035,5.715,24.709,5.916a71.1,71.1,0,0,1,13.864,1.237,35.979,35.979,0,0,1,21.817,13.777c2.9,3.846,5.873,7.631,8.874,11.4a66,66,0,0,0,12.615,12.072,13.692,13.692,0,0,0,14.173,1.582c5.751-2.437,10.862-5.954,14.135-11.531a59.562,59.562,0,0,0,2.92-5.861,61.021,61.021,0,0,0,2.869-7.044c1.571-5.155,2.968-10.333,5.3-15.254a29.449,29.449,0,0,1,11.884-13.1,6.444,6.444,0,0,1,2.538-1.117c2.35-.179,4.348-1.278,6.485-2.051a47.418,47.418,0,0,1,15.969-2.452A104.323,104.323,0,0,0,199.671,81.7a19.873,19.873,0,0,0,7.55-4.065,9.751,9.751,0,0,0,3.445-5.483,18.26,18.26,0,0,0-.677-9.265c-1.638-5.049-4.762-9.15-8.43-12.8a59.936,59.936,0,0,0-20.874-13.643,50.25,50.25,0,0,0-19.806-3.576,213.526,213.526,0,0,1-22.3-.262,147.632,147.632,0,0,1-18.5-3.079,101.52,101.52,0,0,1-10.918-3.249,26.854,26.854,0,0,1-3.869-1.874c-.75-.389-1.423-.946-2.2-1.268-5.932-2.464-10.834-6.451-15.777-10.422A68.36,68.36,0,0,0,77.839,6C72.5,3.044,66.892.6,60.629.279Z"
                style="fill: rgb(205, 214, 37)"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svg__addtime__topright">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            viewBox="-21.4 -8.0 530.9 531.4"
            zoomAndPan="magnify"
            style="fill: rgb(0, 0, 0)"
            role="img"
            aria-label="Abstract Shape Icon"
          >
            <g id="__id1422_s9t6lwvhsh">
              <path
                clip-rule="evenodd"
                d="M64.4,95.4C46.5,171,89.8,280.7,57.2,305.3 c-78.6,59.3-63.1,141.6-31.1,178.9c33.7,39.2,180.6,30.8,207.5-71.3c7.8-29.6,31.1-64.3,55.1-82.7c48.3-37.1,172.5-52.3,201.9-113 c18.9-39.1,16.7-95.1-83-106.6c-76.6-8.8-111.2,13.2-163.7-16.8c-35.8-20.5,4.4-101.8-91.5-93C105,5.1,76.2,45.8,64.4,95.4 L64.4,95.4z"
                fill-rule="evenodd"
                style="fill: rgb(205, 214, 37)"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svg__addtime__downright">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            viewBox="-1.0 0.0 313.8 300.8"
            zoomAndPan="magnify"
            style="fill: rgb(0, 0, 0)"
            role="img"
            aria-label="Wiggly Abstract Shape"
          >
            <g id="__id1420_s8v6vtsulj">
              <path
                d="M3.9,296.5C0,273.7-1,250.4,1,227.3C0.6,151.5,0.3,75.7,4.4,0c101.4,4.2,206.9,3.3,308.4,1.9 c-0.4,20.6-6.2,39.1-18.5,55.8c-4.2,5.7-9,10.9-14.8,14.9c-16.9,11.6-42.3,12.3-52.4,30.2c-8.5,14.9-1.3,33.8-3.5,50.8 c-1.8,14.5-10.6,27.5-22.3,36.2c-11.7,8.7-26,13.4-40.5,15.2c-13,1.6-27.1,1.4-37.6,9.2c-11.9,8.8-15.3,24.7-21.2,38.3 C86.5,288.3,42.8,300.8,3.9,296.5z"
                style="fill: rgb(205, 214, 37)"
              ></path>
            </g>
          </svg>
        </div>
        <div class="svg__addtime__downleft">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            viewBox="-23.2 -36.9 538.7 427.0"
            zoomAndPan="magnify"
            style="fill: rgb(0, 0, 0)"
            role="img"
            aria-label="Abstract shapes"
          >
            <g id="__id1423_s6jbclol1q">
              <path
                d="M43.8,82.3c-49.2,62.1-67,168.8,0,238.3s178.2,42.2,256.7,54.6s175.3,12.4,195.1-96.8 c19.9-109.2-27.3-136.5-161.4-213.5S138.2-36.9,43.8,82.3z"
                style="fill: rgb(205, 214, 37)"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div class="header">
        <img class="header__logo" @click="redirectToDashboard()" :src="img" />
        <button class="header__chatbtn" @click="goback()">Go Back</button>
        <ControlLogout />
      </div>

      <div class="spinner-wrap">
        <Spinner :offersForSpinner="offersForSpinner" />
      </div>
    </div>
  </div>
</template>

<script>
import img from "../assets/images/design/logo.svg";
import ControlLogout from "../components/common/controls/ControlLogout";
import Spinner from "../components/dashboard/Spinner.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  components: { Spinner, ControlLogout },
  data: () => ({
    img,
    modalSpinner: true,
    offersForSpinner: [],
    loading: true,
  }),
  created() {
    this.getOffersForSpinner();
  },
  computed: {
    ...mapState(["balance", "spinner_quantity"]),
  },
  methods: {
    ...mapActions(["setNotification"]),
    redirectToDashboard() {
      this.$router.push({ name: "dashboard" });
    },
    goback() {
      this.$router.go(-1);
    },
    async getOffersForSpinner() {
      try {
        const response = await this.$axios.post("api/spinner/offers");
        const { offers } = response.data;
        this.offersForSpinner = offers;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
