<template>
  <div>
    <div class="loader" v-bind:class="{ hide: !loading }"></div>
    <div v-bind:class="{ hide: loading }">
      <div class="wrapper-for-spinner">
        <div class="spinner__spinandwin justify-center mt-15">
          <div class="spinner__spinandwin__block">
            <img
                class="spinner__spinandwin__img"
                :src="images.logo"
                alt="logo"
            />
          </div>
        </div>
        <div class="spinner__spinandwin">
          <div class="spinner__spinandwin__block">
            SPIN <br />
            AND <br />WIN
          </div>

            <div class="spinner__spinandwin__block">
              APRIL <br />
              PROMO <br />
            </div>
        </div>

        <div class="spinner__block spinner-content d-flex flex-column">
          <div ref="wheel" class="deal-wheel">
            <img
              class="wheel-spinner"
              :src="images.wheel"
              alt="wheel spinner"
              rel="preload"
            />
            <div class="arrow-spin-wrap">
              <img
                class="spinner__arrow"
                :src="images.arrow"
                alt="arrow spinner"
              />
            </div>
            <button
              class="spinner__center"
              @click="
                {
                  {
                    balance >= 0 && isFreeSpin
                      ? wathingad()
                      : redirectToAddSpins();
                  }
                }
              "
            >
              <span
                >{{
                  isSpining != true && balance >= 0 && isFreeSpin
                    ? "Free Spin"
                    : "$" + priceSpin
                }}
              </span>
            </button>
            <ul
              ref="spinner"
              class="spinner"
              :style="{ background: bgSectorsSpinner }"
            >
              <li
                class="prize"
                v-for="({ alias }, index) in offersForSpinner"
                :key="index"
                :class="
                  activeClass && winner.name === name ? selectedClass : ''
                "
                :style="{
                  transform: 'rotate(' + rotationDefault(index) + 'deg)',
                }"
              >
                <span class="text"> {{ alias }}</span>
              </li>
            </ul>
          </div>
          <span class="spinner__text"
            >Spins: {{ spinner_quantity === null ? 0 : spinner_quantity }}</span
          >
        </div>
        <div class="spinner__controls">
          <button
            class="spinner__button"
            :class="
              isSpining ? 'wait' : balance >= 0 && isFreeSpin ? 'animbtn' : ''
            "
            @click="
              {
                {
                  isSpining != true && balance >= 0 && isFreeSpin
                    ? wathingad()
                    : runSpinner();
                }
              }
            "
            ref="attention-btn"
          >
            {{
              isSpining
                ? "Wait"
                : balance >= 0 && isFreeSpin
                ? "Free Spin"
                : "Spin"
            }}
          </button>
          <button class="spinner__button" @click="redirectToAddSpins()">
            Add Spins
          </button>
        </div>
        <button class="spinner__goback" @click="goback()">Back</button>
        <v-dialog v-model="modalVideoAd" class="video">
          <v-card width="100vw; " style="padding: 0; background-color: #000">
            <div class="video__btns">
              <div class="video__button">{{ seconds }}</div>
              <div class="video__title">
                Please, Watch Short Ad To Get Your Free Spin.
              </div>
              <div class="video__button" v-if="skipadbtn" @click="skipad()">
                Skip Ad
              </div>
              <div class="video__button" v-if="skiptime">
                Skip video in {{ sec }}
              </div>
            </div>
            <VideoAd style="padding: 15px"> </VideoAd>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import center from "@/assets/images/spinner/center.png";
import arrow from "@/assets/images/spinner/arrow.png";
import logo from "@/assets/images/spinner/logoTKtop.png";
import wheel from "@/assets/images/spinner/newheel.png";
import VideoAd from "./VideoAd";
import { mapState, mapActions } from "vuex";
export default {
  name: "Spinner",
  props: {
    offersForSpinner: {
      type: Array,
    },
  },
  components: {
    VideoAd,
  },
  data: () => ({
    images: {
      center,
      arrow,
      logo,
      wheel,
    },
    loading: true,
    modalBuySpin: false,
    modalVideoAd: false,
    skipadbtn: false,
    skiptime: false,
    seconds: 30,
    sec: 5,
    timer: "",
    timersec: "",
    styleObject: {
      color: "red",
      fontSize: "15px",
    },
    idWinner: 0,
    currentSlice: 0,
    spinnerStyles: "",
    prizeNodes: "",
    spinClass: "is-spinning",
    selectedClass: "selected",
    isBtnDown: false,
    rotation: 0,
    activeClass: false,
    isFreeSpin: true,
    freeSpinDate: "",
    offerOrder: "",
    winner: { name: "" },
    offerOrderWinner: "",
    currentTime: "",
    wonTime: "",
    attentionAnimation: false,
    priceSpin: "1.99",
    err: false,
    isSpining: false,
    gospin: true,
    colors: [
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#9eff00" },
      { color: "#3d2eff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
      { color: "#ff1f62" },
      { color: "#00abff" },
    ],
  }),
  created() {
    this.fetchFreeSpin();
    this.fetchSpinPrice();
  },
  mounted() {
    this.spinnerStyles = window.getComputedStyle(this.$refs.spinner);
    this.$refs.wheel.addEventListener("transitionend", () => {
      this.endSpinner();
    });
  },
  watch: {
    offerOrder: function (val) {
      if (val) {
        this.winner = this.offersForSpinner[val];
      }
    },
    attentionAnimation: function (val) {
      val
        ? (this.$refs["attention-btn"].$el.style.animationPlayState = "running")
        : (this.$refs["attention-btn"].$el.style.animationPlayState = "paused");
    },
  },
  computed: {
    ...mapState(["spinner_quantity", "balance"]),
    isDisabled() {
      if (this.isFreeSpin) {
        return false;
      }
      if (this.isBtnDown) {
        return true;
      }
      if (this.spinner_quantity === 0 || this.spinner_quantity === null) {
        return true;
      } else {
        return this.spinner_quantity === 0 || this.isBtnDown;
      }
    },
    prizeSlice() {
      return 360 / this.offersForSpinner.length;
    },
    prizeOffset() {
      return Math.floor(180 / this.offersForSpinner.length);
    },
    bgSectorsSpinner() {
      let arr = [];
      this.colors.map(({ color }, i) => {
        arr.push(
          `${color} 0 ${
            (100 / this.offersForSpinner.length) *
            (this.offersForSpinner.length - i)
          }%`
        );
      });
      return `conic-gradient(
      from -90deg,
      ${arr.reverse()}
    )`;
    },
  },
  methods: {
    ...mapActions(["setSpinnerQuantity", "setUserTime", "setNotification"]),
    redirectToAddSpins() {
      this.$router.push({ name: "addspins" });
    },
    goback() {
      this.$router.go(-1);
    },
    async fetchFreeSpin() {
      try {
        const response = await this.$axios.get("/api/spinner/next_free_date");
        this.isFreeSpin = response?.data?.is_spin;
        this.freeSpinDate = response?.data?.next_spin_date;
        this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSpinPrice() {
      try {
        const response = await this.$axios.get("/api/spinner/price");
        this.priceSpin = response?.data?.price;
      } catch (error) {
        console.error(error);
      }
    },
    freeSpinNotification() {
      return `Congratulations You Got ${this.wonTime} Free`;
    },
    confetti() {
      this.$confetti.start();
      setTimeout(() => {
        this.$confetti.stop();
      }, 4000);
    },
    rotationDefault(index) {
      return this.prizeSlice * index * -1 - this.prizeOffset;
    },
    myAD() {
      if (this.seconds > 0) {
        this.seconds--;
      } else {
        clearInterval(this.timer);
        this.skipad();
      }
    },
    myskipAD() {
      if (this.sec > 0) {
        this.sec--;
      } else {
        clearInterval(this.timersec);
        this.skipadbtn = true;
        this.skiptime = false;
      }
    },
    wathingad() {
      this.modalVideoAd = true;
      this.skiptime = true;
      this.skipadbtn = false;
      this.seconds = 30;
      this.sec = 5;
      this.timer = "";
      this.timersec = "";
      if (!this.timer) {
        this.timer = window.setInterval(() => {
          this.myAD();
        }, 1000);
      }
      if (!this.timersec) {
        this.timersec = window.setInterval(() => {
          this.myskipAD();
        }, 1000);
      }
    },
    skipad() {
      clearInterval(this.timer);
      clearInterval(this.timersec);
      this.skipadbtn = false;
      this.modalVideoAd = false;
      this.skiptime = false;
      this.seconds = 30;
      this.sec = 5;
      this.runSpinner();
    },
    runSpinner() {
      this.attentionAnimation = false;
      this.activeClass = false;
      this.isBtnDown = true;

      if (this.isFreeSpin) {
        this.isFreeSpin = false;
      }
      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
      };
      const getSpins = async () => {
        if (this.isSpining != true) {
          this.isSpining = true;
          try {
            const response = await this.$axios.post("api/spinner/spin");
            const { id: idWinner } = response?.data?.offer;
            const userInfo = response?.data?.user;
            this.idWinner = idWinner;
            this.currentTime = userInfo.time;
            this.offerOrder = this.offersForSpinner.findIndex(
              (item) => item?.id === this.idWinner
            );
            this.winner = this.offersForSpinner[this.offerOrder];
            this.wonTime = this.offersForSpinner[this.offerOrder].alias;
            this.setSpinnerQuantity({
              spinner_quantity: userInfo.spinner_quantity,
            });
          } catch (error) {
            console.error(error);
            this.err = true;
            this.isSpining = false;
          } finally {
            setTimeout(() => (this.isSpining = false), 11000);
            const isOfferZero = this.offerOrder === 0;
            this.rotation =
              (360 * 10 + (isOfferZero ? 0 : this.prizeSlice)) *
                (isOfferZero ? 1 : this.offerOrder) +
              getRandomInt(0, this.prizeSlice - this.prizeSlice / 2);
            this.$refs.wheel.classList.add(this.spinClass);
            this.$refs.spinner.style.setProperty("--rotate", this.rotation);
          }
        }
      };
      getSpins();
    },
    endSpinner() {
      if (!this.err) {
        this.confetti();
        this.setNotification({
          show: true,
          type: "success",
          message: this.freeSpinNotification(),
        });
        this.rotation %= 360;
        this.$refs.wheel.classList.remove(this.spinClass);
        this.$refs.spinner.style.setProperty("--rotate", this.rotation);
        this.isBtnDown = false;
        this.setUserTime({
          time: this.currentTime,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
.wait {
  background-color: #7a7a7a;
  color: #fff;
}
</style>
